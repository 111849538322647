import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// hocs
import AuthGuard from '@hocs/auth-guard';
import { CheckPermission } from '@hocs/check-permission';
// components
import { DashboardLayout } from '../../layouts';
// routes
import { LoadingScreen } from '@growdash/design-system/molecules';
import { paths } from '../paths';
import { RequiredPerm } from '../permissions';

const GoalsPage = lazy(() => import('@pages/aisha/aisha'));
const CreateGoalsPage = lazy(() => import('@pages/aisha/create-goals'));
const EditGoalPage = lazy(() => import('@pages/aisha/edit-goal'));

// TODO: fix permissions
export const AishaRoutes = [
  {
    path: paths?.aisha.root,
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: paths.aisha.root,
        element: (
          <CheckPermission
            permissionRequired={RequiredPerm?.Operations_Overview}
          >
            <GoalsPage />
          </CheckPermission>
        ),
        index: true
      },
      {
        path: paths.aisha.create_goals,
        element: (
          <CheckPermission
            permissionRequired={RequiredPerm?.Operations_Overview}
          >
            <CreateGoalsPage />
          </CheckPermission>
        ),
        index: true
      },
      {
        path: 'edit-goals/:id',
        element: (
          <CheckPermission
            permissionRequired={RequiredPerm?.Operations_Overview}
          >
            <EditGoalPage />
          </CheckPermission>
        )
      }
    ]
  }
];
